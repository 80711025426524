import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';
import { CheckCircle2, Clock, Trophy, ArrowRight } from 'lucide-react';
import Analytics from './components/Analytics';

function App() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [formStatus, setFormStatus] = useState({
    submitted: false,
    success: false,
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  // Custom scroll function to correctly position sections
  const scrollToSection = (sectionId, e) => {
    e.preventDefault();
    setMobileMenuOpen(false);
    
    const section = document.getElementById(sectionId);
    if (section) {
      // Get the header height to offset correctly
      const headerHeight = document.querySelector('.main-header').offsetHeight;
      
      // Calculate the correct scroll position
      const scrollPosition = section.offsetTop - headerHeight;
      
      // Scroll to the position
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const response = await fetch('/api/applications', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fullName: formData.name,
          email: formData.email,
          phone: formData.phone,
          program: 'Contact Form Inquiry', // Default for contact form
          message: formData.message
        })
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setFormStatus({
          submitted: true,
          success: true,
          message: 'Your message has been sent successfully! We will get back to you soon.'
        });
        // Reset form
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: ''
        });
      } else {
        setFormStatus({
          submitted: true,
          success: false,
          message: data.message || 'Something went wrong. Please try again.'
        });
      }
    } catch (error) {
      console.error('Form submission error:', error);
      setFormStatus({
        submitted: true,
        success: false,
        message: 'Network error. Please check your connection and try again.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <HelmetProvider>
      <Analytics />
      <div className="App">
        <Helmet>
          {/* Primary Meta Tags */}
          <title>SEI - Nepal's First Bridge Course Institute | Since 1992</title>
          <meta name="title" content="SEI - Nepal's First Bridge Course Institute | Since 1992" />
          <meta name="description" content="Join Nepal's premier bridge course institute with 30,000+ successful alumni. Specialized programs in Science, Management & A-Levels. Morning & day shifts available in New Baneshwor." />
          
          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content="SEI - Bridge Course Excellence Since 1992" />
          <meta property="og:description" content="Nepal's first bridge course institute. 30,000+ alumni. Specialized Science, Management & A-Level programs in New Baneshwor." />
          <meta property="og:image" content="https://sei.edu.np/sei-social-preview.jpg" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:url" content="https://sei.edu.np" />
          <meta property="og:site_name" content="Students' Educational Institute" />
          
          {/* Twitter */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="SEI - Nepal's Premier Bridge Course" />
          <meta name="twitter:description" content="30+ years of academic excellence. Join Nepal's first bridge course institute in New Baneshwor." />
          <meta name="twitter:image" content="https://sei.edu.np/sei-social-preview.jpg" />
          
          {/* Additional SEO Meta */}
          <meta name="keywords" content="bridge course nepal, SEE preparation, +2 preparation, science bridge course, management bridge course, A level preparation, New Baneshwor bridge course" />
          <meta name="author" content="Students' Educational Institute" />
          <meta name="robots" content="index, follow" />
          <meta name="language" content="English" />
          <link rel="canonical" href="https://sei.edu.np" />
          
          {/* Schema.org JSON-LD */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "EducationalOrganization",
                "name": "Students' Educational Institute (SEI)",
                "description": "Nepal's first bridge course institute providing specialized preparation for SEE graduates since 1992.",
                "foundingDate": "1992",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "New Baneshwor, Opposite to BICC South Gate",
                  "addressLocality": "Kathmandu",
                  "addressCountry": "NP"
                },
                "telephone": "01-5320298",
                "email": "info@sei.edu.np",
                "numberOfStudents": "30000+",
                "alumni": "30000+",
                "offers": {
                  "@type": "Offer",
                  "category": ["Science Bridge Course", "Management Bridge Course", "A-Level Bridge Course"]
                }
              }
            `}
          </script>
        </Helmet>

        {/* Header Navigation */}
        <header className="main-header">
          <div className="container">
            <div className="logo">
              <a href="#home" onClick={(e) => scrollToSection('home', e)}>
                <img src="/SEILOGOOO.png" alt="SEI - Students' Educational Institute" className="logo-img" />
              </a>
            </div>
            
            {/* Mobile menu toggle */}
            <div className={`mobile-menu-toggle ${mobileMenuOpen ? 'hidden' : ''}`} onClick={toggleMobileMenu}>
              <span className={`hamburger-icon ${mobileMenuOpen ? 'open' : ''}`}>
                <span></span>
                <span></span>
                <span></span>
              </span>
            </div>
            
            {/* Menu background overlay */}
            <div className={`menu-overlay ${mobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}></div>
            
            <nav className={`main-nav ${mobileMenuOpen ? 'open' : ''}`}>
              <div className="menu-close" onClick={toggleMobileMenu}></div>
              <ul>
                <li><a href="#home" className="active" onClick={(e) => scrollToSection('home', e)}>Home</a></li>
                <li><a href="#programs" onClick={(e) => scrollToSection('programs', e)}>Programs</a></li>
                <li><a href="#testimonials" onClick={(e) => scrollToSection('testimonials', e)}>Testimonials</a></li>
                <li><a href="#contact" onClick={(e) => scrollToSection('contact', e)}>Contact</a></li>
                <li><Link to="/enroll" className="cta-button" onClick={() => setMobileMenuOpen(false)}>Apply Now</Link></li>
              </ul>
            </nav>
          </div>
        </header>

        {/* Hero Section */}
        <section className="hero" id="home">
          <div className="hero-math-symbols"></div>
          <div className="hero-academic"></div>
          <div className="container">
            <div className="hero-content">
              <h1>SEI - Bridge Course 2025</h1>
              <h2>A premier bridge course for <span className="highlight">SEE appeared students</span></h2>
              <div className="location-highlight">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="location-icon">
                  <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
                </svg>
                <p><strong>NEW LOCATION:</strong> We've moved from Putalisadak to New Baneshwor (Opposite to BICC South Gate)</p>
              </div>
              <div className="cta-buttons">
                <a href="#programs" className="secondary-cta" onClick={(e) => scrollToSection('programs', e)}>Explore Programs</a>
              </div>
            </div>
          </div>
        </section>

        {/* Stats Section */}
        <section className="stats">
          <div className="container">
            <div className="stat-item pioneer-stat">
              <span className="stat-number">Since 1992</span>
              <span className="stat-text">Nepal's First Bridge Course</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">30,000+</span>
              <span className="stat-text">Alumni</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">33</span>
              <span className="stat-text">Years of Excellence</span>
            </div>
          </div>
        </section>

        {/* Programs Section */}
        <section className="programs" id="programs">
          <div className="container">
            <div className="program-header-image-wrapper">
              <img src="/classroom-2.jpg" alt="SEI classroom with interactive teaching session" className="program-header-image" />
              <div className="image-caption">Digital Learning Environment</div>
            </div>
            
            <h2 className="section-title">Bridge Course Programs</h2>
            <p className="section-description">Elevate your academic journey with our meticulously designed courses, each tailored to provide exceptional preparation for your specific educational path.</p>
            
            <div className="programs-grid">
              <div className="program-card">
                <h3>Advanced Science Course</h3>
                <div className="program-details">
                  <div className="program-detail"><span>Duration:</span> 10 weeks (4 hours/day)</div>
                  <div className="program-detail"><span>Fee:</span> NPR 14,000</div>
                </div>
                <p>A 10-week intensive program providing a solid scientific foundation with theoretical grounding and practical lab work for SEE graduates pursuing +2 in Science.</p>
                <h4>Curriculum</h4>
                <p>Physics, Chemistry, Zoology, Botany, Mathematics, English, GK & IQ</p>
                <h4>Features</h4>
                <ul>
                  <li>Daily: 3hrs theory + 1hr entrance prep</li>
                  <li>Morning or Day sessions</li>
                  <li>Free subject capsules</li>
                  <li>Practice materials included</li>
                </ul>
              </div>

              <div className="program-card">
                <h3>Entrance Preparation Course</h3>
                <div className="program-details">
                  <div className="program-detail"><span>Duration:</span> 10 weeks (3 hours/day)</div>
                  <div className="program-detail"><span>Fee:</span> NPR 11,000</div>
                </div>
                <p>Tailored program for top college entrance exams like St. Xavier's, CCRC, and Budhanilkantha. Perfect for SEE graduates aiming for competitive admissions.</p>
                <h4>Curriculum</h4>
                <p>Science, Mathematics, English (TOEFL, SAT), GK & IQ</p>
                <h4>Features</h4>
                <ul>
                  <li>2hrs theory + 1hr exam practice</li>
                  <li>Morning or Day sessions</li>
                  <li>Free refreshers</li>
                  <li>Entrance question sets included</li>
                </ul>
              </div>

              <div className="program-card">
                <h3>Advanced Management Course</h3>
                <div className="program-details">
                  <div className="program-detail"><span>Duration:</span> 8 weeks (3 hours/day)</div>
                  <div className="program-detail"><span>Fee:</span> NPR 9,000</div>
                </div>
                <p>An 8-week foundational course for SEE graduates targeting careers in business, finance, or management with focus on core principles.</p>
                <h4>Curriculum</h4>
                <p>Accountancy, Economics, Mathematics, Business Studies, Computer, Hotel Management</p>
                <h4>Features</h4>
                <ul>
                  <li>Comprehensive theoretical foundation</li>
                  <li>Morning or Day sessions</li>
                  <li>Free supplementary materials</li>
                  <li>Course handouts included</li>
                </ul>
              </div>

              <div className="program-card">
                <h3>A-Level Bridge Course</h3>
                <div className="program-details">
                  <div className="program-detail"><span>Duration:</span> 10 weeks (4 hours/day)</div>
                  <div className="program-detail"><span>Fee:</span> NPR 13,500</div>
                </div>
                <p>Specialized 10-week program designed to help SEE graduates secure seats in competitive A-Level colleges and build a solid academic base.</p>
                <h4>Curriculum</h4>
                <p>Physics, Chemistry, Biology, Mathematics, General Paper</p>
                <h4>Features</h4>
                <ul>
                  <li>3hrs theory + 1hr tests/practicals</li>
                  <li>Morning or Day sessions</li>
                  <li>Comprehensive study capsules</li>
                  <li>Complete preparation materials</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Why Choose SEI Section */}
        <section className="why-choose-sei">
          <div className="container">
            <h2 className="section-title">Why Choose SEI for Your Bridge Course?</h2>
            <div className="benefits-grid">
              <div className="benefit-card">
                <div className="benefit-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="custom-icon">
                    <path d="M4,10v8h16v-8l-8-8L4,10z M12,3l6,6v8H6v-8L12,3z M8,14h8v2H8V14z M8,11h8v2H8V11z"/>
                  </svg>
                </div>
                <h3>Pioneer Institution</h3>
                <p>Years of experience delivering comprehensive bridge programs with proven results.</p>
              </div>
              
              <div className="benefit-card">
                <div className="benefit-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="custom-icon">
                    <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M12,4c1.93,0,3.68,0.78,4.95,2.05 C15.68,7.32,13.93,8,12,8S8.32,7.32,7.05,6.05C8.32,4.78,10.07,4,12,4z M12,6c-2.21,0-4,1.79-4,4c0,1.47,0.81,2.75,2,3.45V14c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-0.55 c1.19-0.69,2-1.97,2-3.45C16,7.79,14.21,6,12,6z M12,12c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,12,12,12z"/>
                    <path d="M21,16.5c0-0.17-0.01-0.34-0.03-0.5h-2.04c0.06,0.32,0.07,0.66,0.07,1c0,1.48-0.81,2.78-2,3.45V21c0,0.55-0.45,1-1,1h-4 c-0.55,0-1-0.45-1-1v-0.55C9.81,19.75,9,18.48,9,17c0-0.34,0.01-0.68,0.07-1H7.03C7.01,16.16,7,16.32,7,16.5 c0,3.03,2.47,5.5,5.5,5.5h3C18.53,22,21,19.53,21,16.5z"/>
                  </svg>
                </div>
                <h3>Qualified Faculty</h3>
                <p>Experienced, dedicated instructors focused on student success and academic excellence.</p>
              </div>
              
              <div className="benefit-card">
                <div className="benefit-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="custom-icon">
                    <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M5,5h14v4H5V5z M5,19v-8h14v8H5z"/>
                    <path d="M8,13h8v2H8V13z M8,16h8v2H8V16z"/>
                  </svg>
                </div>
                <h3>Digital Classroom Technology</h3>
                <p>Modern interactive displays and learning tools that enhance understanding through visual learning and digital engagement.</p>
              </div>
              
              <div className="benefit-card">
                <div className="benefit-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="custom-icon">
                    <path d="M12,2L4,5v6.09c0,5.05,3.41,9.76,8,10.91c4.59-1.15,8-5.86,8-10.91V5L12,2z M12,4.11L17,6v5.09c0,3.5-2.2,6.66-5.3,7.91 c-3.1-1.25-5.3-4.41-5.3-7.91V6L12,4.11z"/>
                    <path d="M10,11.3l1.3,1.3l2.9-2.9l1.3,1.3l-4.2,4.2l-2.6-2.6L10,11.3z"/>
                  </svg>
                </div>
                <h3>Scholarships & Rewards</h3>
                <p>Merit-based awards for top performers with weekly tests and cash prizes.</p>
              </div>
              
              <div className="benefit-card">
                <div className="benefit-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="custom-icon">
                    <path d="M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z"/>
                    <path d="M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.7,0-3.24,0.29-4.5,0.83v1.66 C14.13,10.85,15.7,10.5,17.5,10.5z"/>
                    <path d="M13,12.49v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24 C15.8,11.66,14.26,11.96,13,12.49z"/>
                    <path d="M17.5,14.33c-1.7,0-3.24,0.29-4.5,0.83v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26v-1.52 C19.21,14.42,18.36,14.33,17.5,14.33z"/>
                  </svg>
                </div>
                <h3>Extensive Study Materials</h3>
                <p>Complete package including textbooks, model questions, and entrance preparation.</p>
              </div>
              
              <div className="benefit-card">
                <div className="benefit-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="custom-icon">
                    <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/>
                    <path d="M19,9.83V9.59L19.41,9.17C19.16,9.06,18.9,9,18.62,9C18.23,9,17.89,9.13,17.62,9.35L17.38,9.59L17.17,9.83 H19z"/>
                    <path d="M9.83,19H9.59L9.17,19.41C9.06,19.16,9,18.9,9,18.62C9,18.23,9.13,17.89,9.35,17.62L9.59,17.38L9.83,17.17 V19z"/>
                  </svg>
                </div>
                <h3>Career Guidance</h3>
                <p>Counseling, motivational talks, and support for your future academic endeavors.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Scholarships Section */}
        <section className="scholarships">
          <div className="container">
            <div className="scholarship-image-wrapper">
              <img src="/scholarship.png" alt="SEI scholarship recipients with faculty" className="scholarship-header-image" />
            </div>
            
            <div className="scholarship-content">
              <h2 className="scholarship-title">Scholarship, Examination & Awards</h2>
              
              <div className="scholarship-grid">
                <div className="scholarship-block">
                  <h3>Scholarship for Bridge Course</h3>
                  <div className="scholarship-table">
                    <div className="table-heading">
                      <h4>1. Scholarship scheme under sent-up result:</h4>
                    </div>
                    <div className="table-row">
                      <div className="table-cell">Sent-up School 1st</div>
                      <div className="table-cell highlight">100%</div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell">Sent-up School 2nd</div>
                      <div className="table-cell highlight">75%</div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell">Sent-up School 3rd</div>
                      <div className="table-cell highlight">50%</div>
                    </div>
                  </div>
                  
                  <p className="scholarship-note">This scholarship scheme is applicable only for 100 students on first-come-first-serve basis.</p>
                  
                  <div className="table-heading">
                    <h4>SEI also offers scholarships based on school Sent-up GPA as follows:</h4>
                  </div>
                  <div className="scholarship-table">
                    <div className="table-row table-header">
                      <div className="table-cell">Criteria</div>
                      <div className="table-cell">Extent of scholarships</div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell">Securing 3.95 or above in sent-up exam</div>
                      <div className="table-cell highlight">75%</div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell">Securing 3.9 or above in sent-up exam</div>
                      <div className="table-cell highlight">50%</div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell">Securing 3.8 or above in sent-up exam</div>
                      <div className="table-cell highlight">40%</div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell">Securing 3.6 or above in sent-up exam</div>
                      <div className="table-cell highlight">20%</div>
                    </div>
                  </div>
                  
                  <p className="scholarship-note">Students should submit their grade sheet with the recommendation letter from school to avail themselves of this scholarship.</p>
                </div>
                
                <div className="scholarship-block">
                  <div className="table-heading">
                    <h4>2. Prizes for every Saturday model test:</h4>
                  </div>
                  <p className="model-test-description">SEI conducts model tests every Saturday based on the entrance exam of different reputed colleges. SEI provides cash prizes to the toppers of Saturday model test in order to develop feeling of competition and inspire them for their hard work.</p>
                  
                  <div className="scholarship-table">
                    <div className="table-row">
                      <div className="table-cell">1st position</div>
                      <div className="table-cell highlight">Rs. 500</div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell">2nd position</div>
                      <div className="table-cell highlight">Rs. 300</div>
                    </div>
                    <div className="table-row">
                      <div className="table-cell">3rd position</div>
                      <div className="table-cell highlight">Rs. 200</div>
                    </div>
                  </div>
                  
                  <div className="table-heading">
                    <h4>3. Cash prize for Final Examination</h4>
                  </div>
                  <p className="model-test-description">Upon completion of bridge course, SEI conducts The Final Examination covering the total syllabus of the bridge course to assess the overall understanding of students. Topper students of this exam will be awarded cash prizes as follows.</p>
                  
                  <div className="cash-prizes">
                    <div className="cash-prize">
                      <span className="prize-position">1st:</span>
                      <span className="prize-amount">Rs. 8000</span>
                    </div>
                    <div className="cash-prize">
                      <span className="prize-position">2nd:</span>
                      <span className="prize-amount">Rs. 6000</span>
                    </div>
                    <div className="cash-prize">
                      <span className="prize-position">3rd:</span>
                      <span className="prize-amount">Rs. 4000</span>
                    </div>
                  </div>
                  
                  <div className="table-heading">
                    <h4>4. SEI Talent Award:</h4>
                  </div>
                  <p className="talent-award-description">SEI conducts talent hunt program, taking into consideration the different talents a student might have based on their IQ, General Knowledge, Innovations in contemporary issues and special awards will be provided to the winner of this program.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Publications Section */}
        <section className="publications">
          <div className="container">
            <h2 className="section-title">SEI Publications and Study Materials</h2>
            
            <div className="publications-layout">
              <div className="publications-text">
                <p className="publications-description">SEI has published the books and developed study materials needed for the bridge course. These books and study materials are revised and updated timely and will be provided to the students free of cost at a time of admission.</p>
              </div>
              
              <div className="publications-image">
                <img src="/sei-free-books.png" alt="SEI subject books for Physics, Biology, English, Mathematics, Chemistry and GK & IQ" className="books-image" />
              </div>
            </div>
          </div>
        </section>

        {/* Classroom Image Section */}
        <section className="classroom-showcase">
          <div className="container">
            <h2 className="tech-heading">Cutting-Edge Digital Classrooms</h2>
            <p className="tech-description">
              At SEI, we've reimagined education with state-of-the-art digital classrooms that transform how students learn. 
              Our innovative learning spaces feature advanced interactive displays and digital tools that make complex 
              concepts easier to understand and remember.
            </p>
            
            <div className="classroom-content">
              <div className="tech-highlights">
                <div className="tech-feature">
                  <div className="tech-icon">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 3H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 12H4V5h16v10zm-8 6h2v-2h-2v2zm-4-6.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5 1.5zm9 0c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5 1.5zM4 22h16c1.1 0 2-.9 2-2v-1H2v1c0 1.1.9 2 2 2z"/>
                    </svg>
                  </div>
                  <h3>Interactive Touch Displays</h3>
                </div>
                
                <div className="tech-feature">
                  <div className="tech-icon">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21 5c-1.11-.35-2.33-.5-3.5-.5-1.95 0-4.05.4-5.5 1.5-1.45-1.1-3.55-1.5-5.5-1.5S2.45 4.9 1,6v14.65c0 .25.25.5.5.5c0.1 0 .15-.05.25-.05C3.1 20.45 5.05 20 6.5 20c1.95 0 4.05.4 5.5 1.5 1.35-.85 3.8-1.5 5.5-1.5 1.65 0 3.35.3 4.75 1.05.1.05.15.05.25.05.25 0 .5-.25.5-.5.5-.5V6c-.6-.45-1.25-.75-2-1zm0 13.5c-1.1-.35-2.3-.5-3.5-.5c-1.7 0-4.15.65-5.5 1.5V8c1.35-0.85 3.8-1.5 5.5-1.5c1.2 0 2.4.15 3.5.5V18.5z"/>
                    </svg>
                  </div>
                  <h3>Digital Learning Resources</h3>
                </div>
                
                <div className="tech-feature">
                  <div className="tech-icon">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 3L1 9l4 2.18v6L12 21l7-3.82v-6l2-1.09V17h2V9L12 3zm6.82 6L12 12.72 5.18 9 12 5.28 18.82 9zM17 15.99l-5 2.73-5-2.73v-3.72L12 15l5-2.73v3.72z"/>
                    </svg>
                  </div>
                  <h3>Personalized Learning Paths</h3>
                </div>
              </div>
              
              <div className="classroom-image-container">
                <img src="/sei_classroom.jpg" alt="SEI's Modern Digital Classroom" className="classroom-image" />
                <div className="tech-badge">Next-Gen Learning</div>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials" id="testimonials">
          <div className="container">
            <h2 className="section-title">Student Success Stories</h2>
            <div className="testimonials-grid">
              <div className="testimonial-card">
                <div className="testimonial-content">
                  <p>"SEI helped me achieve my academic goals with their excellent faculty and resources. The personalized attention and structured curriculum made a significant difference in my preparation."</p>
                </div>
                <div className="testimonial-author">
                  <h4>Aarav Sharma</h4>
                  <p>Science Stream</p>
                </div>
              </div>

              <div className="testimonial-card">
                <div className="testimonial-content">
                  <p>"The management program at SEI gave me a strong foundation for my business studies. The practical approach to learning and industry exposure was invaluable."</p>
                </div>
                <div className="testimonial-author">
                  <h4>Priya Patel</h4>
                  <p>Management Stream</p>
                </div>
              </div>

              <div className="testimonial-card">
                <div className="testimonial-content">
                  <p>"The humanities program broadened my perspective and prepared me for higher education. The faculty's guidance and comprehensive study materials were exceptional."</p>
                </div>
                <div className="testimonial-author">
                  <h4>Rohan Thapa</h4>
                  <p>Humanities Stream</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Founding Pioneers Section */}
        <section className="founding-pioneers">
          <div className="container">
            <div className="pioneers-content">
              <div className="pioneers-image">
                <img src="/440941813_932833532179930_6480075265529290119_n.jpg" alt="Founding pioneers of SEI bridge course" />
              </div>
              <div className="pioneers-text">
                <h2>Pioneering Excellence</h2>
                <p>We are the founding pioneers of bridge course education in Nepal, establishing the gold standard for academic preparation over three decades. Our purpose-built learning environment has produced over 30,000 successful graduates who have excelled in their chosen fields.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Apply Now Section - Moved before Contact Us */}
        <section className="apply" id="apply">
          <h2 className="section-title">Start Your Journey</h2>
          <p className="section-description">
            Join our community of achievers and unlock your potential.
          </p>
          
          <div className="apply-benefits">
            <div className="benefit">
              <CheckCircle2 className="benefit-icon" />
              <div className="benefit-content">
                <span className="benefit-title">Easy Process</span>
                <p className="benefit-description">Quick and simple application process.</p>
              </div>
            </div>
            
            <div className="benefit">
              <Clock className="benefit-icon" />
              <div className="benefit-content">
                <span className="benefit-title">Flexible Schedule</span>
                <p className="benefit-description">Morning and day shifts available.</p>
              </div>
            </div>
            
            <div className="benefit">
              <Trophy className="benefit-icon" />
              <div className="benefit-content">
                <span className="benefit-title">Scholarships</span>
                <p className="benefit-description">Merit-based awards available.</p>
              </div>
            </div>
          </div>

          <a href="/enroll" className="apply-cta">
            Apply Now
            <ArrowRight className="arrow-icon" />
          </a>
        </section>

        {/* Contact Section */}
        <section className="contact" id="contact">
          <div className="container">
            <h2 className="section-title">Contact Us</h2>
            <p className="section-description">Get in touch with us for any inquiries about our programs or enrollment process.</p>
            
            <div className="contact-container">
              <div className="contact-info">
                <div className="contact-details">
                  <div className="contact-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="contact-icon">
                      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
                    </svg>
                    <div>
                      <h3>Location</h3>
                      <p>New Baneshwor (Opposite to BICC South Gate)</p>
                    </div>
                  </div>
                  <div className="contact-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="contact-icon">
                      <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"/>
                    </svg>
                    <div>
                      <h3>Phone</h3>
                      <p>+977 1234567890</p>
                    </div>
                  </div>
                  <div className="contact-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="contact-icon">
                      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
                    </svg>
                    <div>
                      <h3>Email</h3>
                      <p>info@sei.edu.np</p>
                    </div>
                  </div>
                </div>
                
                <div className="contact-form">
                  <form onSubmit={handleFormSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Your Email"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        placeholder="Your Phone"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        placeholder="Your Message"
                        required
                      ></textarea>
                    </div>
                    <button type="submit" className="submit-button" disabled={loading}>
                      {loading ? 'Sending...' : 'Send Message'}
                    </button>
                  </form>
                  {formStatus.submitted && (
                    <div className={`form-message ${formStatus.success ? 'success' : 'error'}`}>
                      {formStatus.message}
                    </div>
                  )}
                </div>
              </div>
              
              <div className="map-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1000!2d85.33726381540039!3d27.68722146320337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjfCsDQxJzE0LjAiTiA4NcKwMjAnMTQuMiJF!5e0!3m2!1sen!2snp!4v1234567890!5m2!1sen!2snp&markers=color:red%7Clabel:SEI%7C27.68722146320337,85.33726381540039&zoom=16"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="SEI Location Map"
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        {/* Footer */}
        <footer className="main-footer">
          <div className="container">
            <div className="footer-content">
              <div className="footer-logo">
                <p>In Knowledge We Trust</p>
              </div>
              <div className="footer-links">
                <h3>Quick Links</h3>
                <ul>
                  <li><a href="#home" onClick={(e) => scrollToSection('home', e)}>Home</a></li>
                  <li><a href="#programs" onClick={(e) => scrollToSection('programs', e)}>Programs</a></li>
                  <li><a href="#testimonials" onClick={(e) => scrollToSection('testimonials', e)}>Testimonials</a></li>
                  <li><a href="#contact" onClick={(e) => scrollToSection('contact', e)}>Contact</a></li>
                </ul>
              </div>
              <div className="footer-contact">
                <h3>Contact Us</h3>
                <p>New Baneshwor, Opposite to BICC South Gate, Kathmandu</p>
                <p>01-5320298, 9764569539</p>
                <p>info@sei.edu.np</p>
              </div>
            </div>
            <div className="footer-bottom">
              <p>© 2024 SEI. All rights reserved.</p>
            </div>
          </div>
        </footer>

        {/* Floating Location Badge */}
        <a href="#contact" className="floating-location-badge" onClick={(e) => scrollToSection('contact', e)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="floating-badge-icon">
            <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
          </svg>
          <div className="floating-badge-text">We've moved to New Baneshwor!</div>
        </a>
      </div>
    </HelmetProvider>
  );
}

export default App;
