import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './App.css';

function EnrollmentPage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Simplified useEffect that only resets the page's scroll position
  useEffect(() => {
    // Reset scroll position to top
    window.scrollTo(0, 0);
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="App">
      {/* Header Navigation */}
      <header className="main-header">
        <div className="container">
          <div className="logo">
            <Link to="/">
              <img src="/SEILOGOOO.png" alt="SEI - Students' Educational Institute" className="logo-img" />
            </Link>
          </div>
          
          {/* Mobile menu toggle */}
          <div className={`mobile-menu-toggle ${mobileMenuOpen ? 'hidden' : ''}`} onClick={toggleMobileMenu}>
            <span className={`hamburger-icon ${mobileMenuOpen ? 'open' : ''}`}>
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          
          {/* Menu background overlay */}
          <div className={`menu-overlay ${mobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}></div>
          
          <nav className={`main-nav ${mobileMenuOpen ? 'open' : ''}`}>
            <div className="menu-close" onClick={toggleMobileMenu}></div>
            <ul>
              <li><Link to="/" onClick={() => setMobileMenuOpen(false)}>Back to Home</Link></li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Enrollment Form Section */}
      <section className="enrollment">
        <div className="container">
          <h2 className="section-title">Enrollment Form</h2>
          <p className="section-description">Fill out the form below to apply for SEI courses.</p>
          
          <div className="enrollment-form">
            <iframe 
              id="enrollment-form-iframe"
              src="https://docs.google.com/forms/d/e/1FAIpQLSdoSyJDGWem3QMo3QtZ8NMTNiySUTKQAKBsPT0rPP2oG4WeQw/viewform?embedded=true" 
              width="100%" 
              title="SEI Enrollment Form"
            >
              Loading…
            </iframe>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="main-footer">
        <div className="container">
          <div className="footer-content">
            <div className="footer-logo">
              <p>In Knowledge We Trust</p>
            </div>
            <div className="footer-links">
              <h3>Quick Links</h3>
              <ul>
                <li><Link to="/">Home</Link></li>
              </ul>
            </div>
            <div className="footer-contact">
              <h3>Contact Us</h3>
              <p>New Baneshwor, Opposite to BICC South Gate, Kathmandu</p>
              <p>01-5320298, 9764569539</p>
              <p>info@sei.edu.np</p>
            </div>
          </div>
          <div className="footer-bottom">
            <p>© 2024 SEI. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default EnrollmentPage; 